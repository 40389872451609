<template>
<div class="checkpoint-preview">
  <div class="preview-window">
    <columns>
      <column>
        <h5 class="title is-5 is-marginless has-text-info">
          <span v-if="checkpoint.instruction">1. </span>
          {{ checkpoint.instruction || 'Type an instruction' }}
        </h5>
        <p class="help">{{ checkpoint.additional_information }}</p>
      </column>
    </columns>
    <div class="activities">
      <component v-if="checkpoint.checkpoint_type_id" :is="checkpointType.builder + '-preview'" />
      <div v-else>
        Please Select a checkpoint type
      </div>
      
      <columns>
        <column>
          <p class="has-text-weight-bold has-text-info">Imagery</p>
          <div class="photo-row">
            <div class="photo-example">
              <icon icon="camera-alt" class="fa-2x"/>
            </div>
            <div class="photo-example">
              <icon icon="camera-alt" class="fa-2x"/>
            </div>
            <div class="photo-example">
              <icon icon="camera-alt" class="fa-2x"/>
            </div>
          </div>
        </column>
      </columns>
    </div>

    <div class="bottom">
      <text-area :rows="2">
        <span class="has-text-info">Notes</span>
      </text-area>
      <action-button class="is-fullwidth">Next Checkpoint</action-button>
    </div>
  </div>
</div>  
</template>
<script>
import { mapGetters } from 'vuex'
import { find } from 'lodash'
export default {
  
  computed: {
    ...mapGetters('checkpoint', [
      'checkpoint',
      'checkpoint_types'
    ]),
    checkpointType() {
      return find(this.checkpoint_types, type => type.id === this.checkpoint.checkpoint_type_id)
    }
  },

}
</script>