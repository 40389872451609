<template>
<loader v-bind="{ loading }">
  <columns>
    <column>
        <page-heading
          heading="Edit Checkpoint"
        />
    </column>
    <column class="is-narrow">
      <div class="buttons">
        <router-link class="button is-small is-rounded is-ghost" :to="{ name: 'checkpoints' }">Back</router-link>
        <router-link 
          class="is-flex button is-rounded is-small" 
          :to="{
            name: 'create-checkpoint'
          }">
            <icon icon="plus"/>
            <span>Checkpoint</span>
        </router-link>
      </div>
    </column>
  </columns>
  <form>
    <columns>
      <column>
        <columns>
          <column>
            <text-input
              classes="is-rounded is-medium"
              :error="$root.errors.instruction"
              @input="updateInstruction"
              :value="checkpoint.instruction"
              placeholder="Take a reading of the current mileage of the odometer"
              required>
              Instruction
            </text-input>
          </column>
        </columns>
        <columns>
          <column>
            <text-input
              classes="is-rounded is-medium"
              :error="$root.errors.additional_information"
              @input="updateAdditionalInformation"
              :value="checkpoint.additional_information"
              placeholder="Some addtional information to aid the user in completing the instruction">
              Additional Information <small class="has-text-weight-light">(optional)</small>
            </text-input>
          </column>
          <column class="is-4">
            <switch-input
              classes="is-rounded is-medium"
              @input="togglePointOfFailure"
              :error="$root.errors.point_of_failure"
              :value="checkpoint.point_of_failure"
              :items="failure_options"
              hint="Mark this checkpoint as a critical checkpoint that will fail the entire checklist given a poor outcome">
              Point of failure <small class="has-text-weight-light">(optional)</small>
            </switch-input>
          </column>
        </columns>
        <columns>
          <column>
            <select-input
              required
              no-revert
              classes="is-rounded is-medium"
              :error="$root.errors.comment_configuration"
              :items="comment_configurations"
              :value="checkpoint.comment_configuration"
              @input="updateCommentConfiguration">
              Comment Configuration
            </select-input>
          </column>
          <column>
            <select-input
              required
              no-revert
              classes="is-rounded is-medium"
              :error="$root.errors.image_configuration"
              :items="image_configurations"
              :value="checkpoint.image_configuration"
              @input="updateImageConfiguration">
              Image Configuration
            </select-input>
          </column>
        </columns>

        <checkpoint-type-builder
          v-if="checkpoint.checkpoint_type_id"
        />

        <columns>
          <column>
            <submit-button
              class="is-medium is-rounded"
              :working="updating"
              @submit="saveCheckpoint">
              Save Checkpoint
            </submit-button>
          </column>
        </columns>

      </column>
      <column class="is-4">
        <p class="has-text-weight-bold has-text-right">Checkpoint Preview</p>
        <checkpoint-preview />
      </column>
    </columns>
  </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import CheckpointPreview from './preview/Preview.vue'
import CheckpointTypeBuilder from './builder/Builder'

export default {

  components: {
    CheckpointPreview,
    CheckpointTypeBuilder
  },

  data: () => ({
    loading: true,
    updating: false,
    checklists: [],
    failure_options: [
      {
        value: false,
        label: 'No'
      },
      {
        value: true,
        label: 'Yes'
      }
    ]
  }),

  beforeDestroy() {
    this.clearCheckpoint()
  },

  async beforeCreate() {
    await this.$store.dispatch('checkpoint/loadTypes')
    await this.$store.dispatch('checkpoint/load', this.$route.params.checkpoint)
    
    this.loading = false
  },

  methods: {
    ...mapMutations('checkpoint', [
      'updateInstruction',
      'clearCheckpoint',
      'updateType',
      'updateCommentConfiguration',
      'updateImageConfiguration',
      'updateAdditionalInformation',
      'updateChecklists',
      'togglePointOfFailure'
    ]),
    saveCheckpoint() {
      this.updating = true
      this.$store.dispatch('checkpoint/update').then(() => {
        this.$toast.success('Checkpoint successfully updated')
        this.updating = false
      }).catch(() => {
        this.updating = false
        this.$toast.error('Please ensure all required fields are completed')
      })
    }
  },

  computed: mapGetters('checkpoint', [
    'checkpoint',
    'checkpoint_types',
    'comment_configurations',
    'image_configurations',
  ])

}
</script>